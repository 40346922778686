@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .navbar-main {
        @apply bg-black/25 flex h-20 w-screen justify-around items-center md: flex-col md:w-24 md:h-screen grow-0 shrink-0 fixed top-0 p-4 gap-1;
    }

    .navbar-button {
        @apply bg-neutral-700 hover:bg-main-blue active:bg-active-blue focus:ring-blue-300 text-neutral-200 hover:text-white active:text-white relative flex items-center justify-center w-12 h-12 md:w-16 md:h-16 my-2 mx-auto focus:ring-4 rounded-xl hover:rounded-xl transition-all duration-300 ease-in-out cursor-pointer;
    }

    .profile-button {
        @apply bg-gray-800 hover:bg-highlight-blue active:bg-active-blue focus:ring-blue-300 text-main-blue hover:text-white active:text-white relative flex items-center justify-center rounded-3xl hover:rounded-xl transition-all duration-300 ease-in-out cursor-pointer focus:ring-4 w-20 h-20 lg:w-28 lg:h-28 focus:outline-none;
    }

    .profile-icon {
        @apply text-[34px] lg:text-[48px];
    }

    .user-button {
        @apply relative flex items-center justify-center w-12 h-12 my-2 mx-auto focus:ring-4 rounded-xl hover:rounded-xl transition-all duration-300 ease-in-out cursor-pointer;
    }

    .user-icon {
        @apply text-[28px];
    }

    .navbar-tooltip {
        @apply bg-gray-900 text-white absolute w-auto p-2 m-2 min-w-max -left-[50%] top-8 md:left-0 md:top-5 rounded-md shadow-md text-xs font-bold transition-all duration-100 scale-0 md:origin-center z-auto;
    }

    .icon-button-style {
        @apply bg-neutral-700 hover:bg-main-blue active:bg-active-blue focus:ring-blue-300 text-neutral-200 hover:text-white active:text-white;
    }

    .button-style {
        @apply bg-main-blue hover:bg-active-blue focus:ring-4 focus:ring-blue-800 text-black hover:text-main-text m-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none transition-all duration-300;
    }

    /* .modal-button-style {
        @apply bg-modal-white hover:bg-active-blue focus:ring-4 focus:ring-blue-800 text-black hover:text-main-blue m-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none transition-all duration-300;
    } */

    .confirm-button-style {
        @apply bg-green-700 hover:bg-green-900 focus:ring-4 focus:ring-blue-800 text-black hover:text-white m-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none transition-all duration-300;
    }

    .delete-button-style {
        @apply bg-red-700 hover:bg-red-900 focus:ring-4 focus:ring-blue-800 text-black hover:text-white m-4 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none transition-all duration-300;
    }

    .title-text {
        @apply text-center text-4xl font-display-text-f mb-6;
    }

    .heading-text {
        @apply text-center text-3xl mb-2 font-main-text-f;
    }

    .h2-text {
        @apply text-center text-2xl font-main-text-f;
    }

    .h3-text {
        @apply text-center text-xl font-main-text-f;
    }

    .cards-container {
        @apply text-main-bg w-full h-auto p-6 rounded;
    }

    .card-background {
        @apply bg-gradient-to-r from-main-bg to-alt-bg text-main-text border-highlight-blue;
    }

    .card-style {
        @apply p-8 m-2 rounded-2xl border-2;
    }

    .card-flex {
        @apply flex flex-col;
    }

    .modal-header {
        @apply bg-active-blue;
    }

    .modal-body {
        @apply bg-modal-gray text-black;
    }

    .center-all {
        @apply justify-center items-center text-center;
    }

    .gl-scrollbar {
        @apply scrollbar-thin scrollbar-track-alt-bg scrollbar-thumb-highlight-blue;
    }

}

body {
    background-color: #171717;
    color: white;
}

input, ::selection {
    color: black;
}

/* Pretty Links */
a {
    text-decoration: none;
    color: white
}